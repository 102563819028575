import React from 'react';
import {BrowserRouter as Router, Redirect, Route, withRouter, Switch} from 'react-router-dom';
import Page from './components/Page';
import {Error} from './Error';
import {Home} from './Home';
import {About} from './About';
import {News} from './News';
import {Events} from './Events';
import {Contact} from './Contact';
import {Posts} from './Posts';
import {Tour} from './Tour';
import {Tour2021} from './Tour2021';
import {Tour2022} from './Tour2022';
import {Tour2023} from './Tour2023';
import {Tour2024} from './Tour2024';
import {NavBarCustom} from './components/NavBar';
import './App.css';

import {Quadratic} from './posts/Quadratic';
import {QuadraticRelated} from './posts/QuadraticRelated';
import {QuadraticDetail} from './posts/QuadraticDetail';
import {QuadraticCompSqr} from './posts/QuadraticCompSqr';
import {QuadraticLogicExist} from './posts/QuadraticLogicExist';

function App() {

  const HeaderWithRouter = withRouter(NavBarCustom);
  
  return (
    <React.Fragment>
      <Router>
        <HeaderWithRouter/>
        <Switch>
          <Route exact path="/" render = {props => (
            <Page {...props} component = {Home} title = "" />
          )}/>
          <Route exact path="/about" render = {props => (
            <Page {...props} component = {About} title = "About" />
          )}/>
          <Route exact path="/news" render = {props => (
            <Page {...props} component = {News} title = "News" />
          )}/>
          <Route exact path="/events" render = {props => (
            <Page {...props} component = {Events} title = "Events" />
          )}/>
          <Route exact path="/posts" render = {props => (
            <Page {...props} component = {Posts} title = "Posts" />
          )}/>
          <Route exact path="/contact" render = {props => (
            <Page {...props} component = {Contact} title = "Contact" />
          )}/>
          <Route exact path="/live" render = {props => (
            <Redirect to="/tour" />
          )}/>
          <Route exact path="/tour" render = {props => (
            <Page {...props} component = {Tour} title = "Tour" />
          )}/>
          <Route exact path="/tour2021" render = {props => (
            <Page {...props} component = {Tour2021} title = "Tour 2021" />
          )}/>
          <Route exact path="/tour2022" render = {props => (
            <Page {...props} component = {Tour2022} title = "Tour 2022" />
          )}/>
          <Route exact path="/tour2023" render = {props => (
            <Page {...props} component = {Tour2023} title = "Tour 2023" />
          )}/>
          <Route exact path="/tour2024" render = {props => (
            <Page {...props} component = {Tour2024} title = "Tour 2024" />
          )}/>
          <Route exact path="/quadratic" render = {props => (
            <Page {...props} component = {Quadratic} title = "A Different Way to Solve Quadratic Equations" />
          )}/>
          <Route exact path="/quadraticdetail" render = {props => (
            <Page {...props} component = {QuadraticDetail} title = "Quadratic Method: Detailed Explanation" />
          )}/>
          <Route exact path="/quadraticrelated" render = {props => (
            <Page {...props} component = {QuadraticRelated} title = "Quadratic Method: Related Work" />
          )}/>
          <Route exact path="/quadraticcompsqr" render = {props => (
            <Page {...props} component = {QuadraticCompSqr} title = "Quadratic Method: Completing the Square" />
          )}/>
          <Route exact path="/quadraticlogicexist" render = {props => (
            <Page {...props} component = {QuadraticLogicExist} title = "Quadratic Method: Existence Logic" />
          )}/>
          <Route path="*" render = {props => (
            <Page {...props} component={Error} title="404 Not Found" />
          )}/>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
