import React from 'react';
import styled from 'styled-components';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;


export const Error = () => {
  return (
    <Styles>
      <CustomJumbotron bgimage={ImgHeader}>Page Not Found</CustomJumbotron>
      <Footer/>
    </Styles>
  );
}
